// /var/www/myapp-client/src/components/ManageWebsites.js

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { TextField, Button, List, ListItem, ListItemText, IconButton, Paper, Typography, Box, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AnalyzeIcon from '@mui/icons-material/Assessment';
import { fetchWebsites, addWebsite, deleteWebsite, analyzeWebsite } from '../services/apiService';
import { removeWebsite as removeWebsiteFromStore, updateWebsite, addWebsite as addWebsiteToStore } from '../store/adminSlice'; // Убедитесь, что используете правильный импорт

const ManageWebsites = () => {
  const dispatch = useDispatch();
  const websites = useSelector((state) => state.admin.websites);
  const [formValues, setFormValues] = useState({
    name: '',
    yandexMetrikaToken: '',
    googleAnalyticsToken: '',
    url: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadWebsites = async () => {
      setLoading(true);
      try {
        await dispatch(fetchWebsites()).unwrap(); // Используем fetchWebsites для загрузки данных
      } catch (error) {
        toast.error('Ошибка при загрузке сайтов');
      } finally {
        setLoading(false);
      }
    };
    loadWebsites();
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleAddWebsite = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const newWebsite = await addWebsite(formValues);
      dispatch(addWebsiteToStore(newWebsite)); // Обновление состояния через Redux
      toast.success('Сайт добавлен');
      setFormValues({ name: '', yandexMetrikaToken: '', googleAnalyticsToken: '', url: '' });
    } catch (error) {
      toast.error('Ошибка при добавлении сайта');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteWebsite = async (id) => {
    setLoading(true);
    try {
      await deleteWebsite(id);
      dispatch(removeWebsiteFromStore(id)); // Обновляем состояние через Redux
      toast.success('Сайт удален');
    } catch (error) {
      toast.error('Ошибка при удалении сайта');
    } finally {
      setLoading(false);
    }
  };

  const handleAnalyzeWebsite = async (id) => {
    setLoading(true);
    try {
      const analysisResult = await analyzeWebsite(id);
      dispatch(updateWebsite(analysisResult));
      toast.success('Анализ завершен');
    } catch (error) {
      toast.error('Ошибка при анализе сайта');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, marginTop: 4 }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Управление сайтами
      </Typography>
      <form onSubmit={handleAddWebsite}>
        <Box sx={{ marginBottom: 2 }}>
          <TextField
            label="Название сайта"
            name="name"
            type="text"
            value={formValues.name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <TextField
            label="Токен Яндекс Метрики"
            name="yandexMetrikaToken"
            type="text"
            value={formValues.yandexMetrikaToken}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <TextField
            label="Токен Google Analytics"
            name="googleAnalyticsToken"
            type="text"
            value={formValues.googleAnalyticsToken}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <TextField
            label="URL сайта"
            name="url"
            type="url"
            value={formValues.url}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
        </Box>
        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? 'Добавление...' : 'Добавить сайт'}
        </Button>
      </form>
      {loading && <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />}
      <List>
        {websites.map((website) => (
          <ListItem key={website._id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <ListItemText primary={website.name} secondary={website.url} />
            <Box>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteWebsite(website._id)} disabled={loading}>
                <DeleteIcon />
              </IconButton>
              <IconButton edge="end" aria-label="analyze" onClick={() => handleAnalyzeWebsite(website._id)} disabled={loading}>
                <AnalyzeIcon />
              </IconButton>
            </Box>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default ManageWebsites;



