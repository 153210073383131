// /var/www/myapp-client/src/components/UserDashboard.js

import React from 'react';
import RechartsLineChart from './RechartsLineChart';
import Metrics from './Metrics';
import Notification from './Notification';
import { Grid, Typography, Paper } from '@mui/material';
import withDataFetching from './withDataFetching';
import { fetchUserMetrics } from '../api';

const UserDashboard = ({ data: metricsData }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography variant="h4" gutterBottom>
            Панель пользователя
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <RechartsLineChart
          data={metricsData}
          title="Посещаемость сайта"
          xAxisKey="date"
          lines={[
            { key: 'sessions', color: '#8884d8' },
            { key: 'bounceRate', color: '#82ca9d' },
          ]}
          grid
          tooltip
          legend
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Metrics />
      </Grid>

      <Grid item xs={12}>
        <Notification />
      </Grid>
    </Grid>
  );
};

export default withDataFetching(UserDashboard, fetchUserMetrics);

