// /var/www/myapp-client/src/components/TrendCharts.js

import React from 'react';
import { Line } from 'react-chartjs-2';
import { Paper, Typography, Box } from '@mui/material';

const TrendCharts = ({ trendData, title = 'Trend Analysis', xAxisLabel = 'Date', yAxisLabel = 'Pageviews' }) => {
  // Настройки графика
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: xAxisLabel,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
        },
      },
      y: {
        title: {
          display: true,
          text: yAxisLabel,
        },
        ticks: {
          callback: function(value) {
            return value.toLocaleString();
          },
        },
      },
    },
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, marginTop: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        {title}
      </Typography>
      <Box sx={{ height: 400 }}>
        {trendData && trendData.labels && trendData.datasets ? (
          <Line data={trendData} options={chartOptions} />
        ) : (
          <Typography variant="body1">Нет данных для отображения</Typography>
        )}
      </Box>
    </Paper>
  );
};

export default TrendCharts;

