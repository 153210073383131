// /var/www/myapp-client/src/store/store.js

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import userReducer from './userSlice';
import adminReducer from './adminSlice';
import notificationsReducer from './notificationsSlice';
import filterReducer from './filterSlice'; // Импорт редьюсера для фильтров

// Конфигурация для persistReducer
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'], // Сохраняем только user reducer
  blacklist: ['notifications', 'filter'], // Исключаем из сохранения ненужные данные
};

// Объединяем редьюсеры в rootReducer
const rootReducer = combineReducers({
  user: userReducer,
  admin: adminReducer,
  notifications: notificationsReducer,
  filter: filterReducer,
});

// Оборачиваем rootReducer в persistReducer для сохранения состояния
const rootPersistedReducer = persistReducer(persistConfig, rootReducer);

// Создаем store с настройками
const store = configureStore({
  reducer: rootPersistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Отключаем проверку сериализуемости для улучшения производительности
    }),
  devTools: process.env.NODE_ENV !== 'production', // Включаем Redux DevTools только в режиме разработки
});

// Создаем persistor для сохранения состояния
const persistor = persistStore(store);

export { store, persistor };