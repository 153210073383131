// /var/www/myapp-client/src/components/AdminSettings.js

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { TextField, Button, Paper, Typography, Box } from '@mui/material';
import adminService from '../services/adminService'; // Импорт adminService
import { updateAdminSettingsInStore } from '../store/adminSlice'; // Импортируем действие

const AdminSettings = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.admin.settings || {});

  const [localSettings, setLocalSettings] = useState({
    model: settings.model || '',
    prompt: settings.prompt || '',
    max_tokens: settings.max_tokens || 200,
  });

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const fetchedSettings = await adminService.fetchAdminSettings(); // Используем adminService для получения настроек
        setLocalSettings(fetchedSettings);
      } catch (error) {
        toast.error(`Ошибка при загрузке настроек: ${error.message}`);
      }
    };
    if (!settings.model) {
      loadSettings();
    }
  }, [settings]);

  const handleChange = (e) => {
    setLocalSettings((prevSettings) => ({
      ...prevSettings,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await adminService.updateAdminSettings(localSettings); // Обновление настроек через adminService
      dispatch(updateAdminSettingsInStore(localSettings)); // Обновление состояния в Redux
      toast.success('Настройки успешно обновлены');
    } catch (error) {
      toast.error(`Ошибка при обновлении настроек: ${error.message}`);
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, marginTop: 4 }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Настройки администратора
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box sx={{ marginBottom: 2 }}>
          <TextField
            label="Модель"
            type="text"
            name="model"
            value={localSettings.model}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <TextField
            label="Подсказка"
            name="prompt"
            value={localSettings.prompt}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <TextField
            label="Максимум токенов"
            type="number"
            name="max_tokens"
            value={localSettings.max_tokens}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Обновить настройки
        </Button>
      </form>
    </Paper>
  );
};

export default AdminSettings;
