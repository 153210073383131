// /var/www/myapp-client/src/components/OpenAIQuery.js

import React, { useState } from 'react';
import { sendOpenAIQuery } from '../services/apiService';
import { toast } from 'react-toastify';
import { TextField, Button, Paper, Typography, Box, CircularProgress } from '@mui/material';
import { handleApiError } from '../services/errorHandler'; // Импорт улучшенного обработчика ошибок

const OpenAIQuery = () => {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(''); // Состояние для хранения ошибок

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!query.trim()) {
      toast.warn('Пожалуйста, введите запрос', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setLoading(true);
    setError(''); // Сброс ошибки при новой попытке
    try {
      const res = await sendOpenAIQuery(query);
      setResponse(res.response);
      toast.success('Запрос успешно выполнен!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      handleApiError(error, 'Ошибка при выполнении запроса к OpenAI'); // Использование улучшенного обработчика ошибок
      setError(error.message || 'Ошибка при выполнении запроса к OpenAI');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Запрос к OpenAI
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box sx={{ marginBottom: 2 }}>
          <TextField
            label="Запрос"
            name="query"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            fullWidth
            multiline
            rows={4}
            margin="normal"
            error={!!error} // Отображение ошибки
            helperText={error} // Текст ошибки
          />
        </Box>
        <Button type="submit" variant="contained" color="primary" disabled={loading} sx={{ position: 'relative' }}>
          {loading && <CircularProgress size={24} sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
          {loading ? 'Отправка...' : 'Отправить'}
        </Button>
      </form>
      {response && (
        <Paper elevation={3} sx={{ padding: 2, marginTop: 4 }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Ответ
          </Typography>
          <Box sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            <pre>{response}</pre>
          </Box>
        </Paper>
      )}
    </Paper>
  );
};

export default OpenAIQuery;
