// /var/www/myapp-client/src/components/Profile.js

import React, { useState, useEffect } from 'react';
import { TextField, Button, Paper, Typography, Box, CircularProgress } from '@mui/material';
import userService from '../services/userService'; // Импорт из userService
import { toast } from 'react-toastify';

const Profile = () => {
  const [user, setUser] = useState({
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    phoneNumber: '',
    avatar: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserProfile = async () => {
      setLoading(true);
      try {
        const data = await userService.getUserProfile(); // Использование userService
        setUser(data);
      } catch (error) {
        setError(error.message || 'Ошибка при получении профиля пользователя');
        toast.error(error.message || 'Ошибка при получении профиля пользователя');
      } finally {
        setLoading(false);
      }
    };
    fetchUserProfile();
  }, []);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await userService.updateUserProfile(user); // Использование userService
      toast.success('Профиль обновлен');
    } catch (error) {
      setError(error.message || 'Ошибка при обновлении профиля');
      toast.error(error.message || 'Ошибка при обновлении профиля');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
      <Typography variant="h5" component="h1" gutterBottom>
              Профиль
      </Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {error && (
            <Typography variant="body1" color="error" sx={{ marginBottom: 2 }}>
              {error}
            </Typography>
          )}
          <form onSubmit={handleSubmit}>
            <TextField
              label="Имя пользователя"
              type="text"
              name="username"
              value={user.username}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              type="email"
              name="email"
              value={user.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Имя"
              type="text"
              name="firstName"
              value={user.firstName}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Фамилия"
              type="text"
              name="lastName"
              value={user.lastName}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Дата рождения"
              type="date"
              name="dateOfBirth"
              value={user.dateOfBirth}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Пол"
              type="text"
              name="gender"
              value={user.gender}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Телефон"
              type="tel"
              name="phoneNumber"
              value={user.phoneNumber}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Аватар"
              type="url"
              name="avatar"
              value={user.avatar}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading ? 'Обновление...' : 'Обновить профиль'}
            </Button>
          </form>
        </>
      )}
    </Paper>
  );
};

export default Profile;


