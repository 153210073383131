// /var/www/myapp-client/src/components/withDataFetching.js

import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import errorHandler from '../services/errorHandler'; // Импортируем улучшенный errorHandler

const withDataFetching = (WrappedComponent, fetchDataFn) => {
  const EnhancedComponent = (props) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
      const loadData = async () => {
        try {
          const result = await fetchDataFn();
          setData(result);
        } catch (err) {
          errorHandler(err); // Обрабатываем ошибку через errorHandler
          setError(`Ошибка при загрузке данных: ${err.message}`);
        } finally {
          setLoading(false);
        }
      };

      loadData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Убираем fetchDataFn из зависимостей


    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return (
        <Typography color="error" align="center">
          {error}
        </Typography>
      );
    }

    return <WrappedComponent data={data} {...props} />;
  };

  EnhancedComponent.propTypes = {
    data: PropTypes.any,
    fetchDataFn: PropTypes.func.isRequired,
  };

  return EnhancedComponent;
};

export default withDataFetching;

