// /var/www/myapp-client/src/components/Metrics.js

import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { utils, writeFile } from 'xlsx';
import { saveAs } from 'file-saver';
import { Parser } from 'json2csv';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MetricCharts from './MetricCharts';
import SummaryReport from './SummaryReport';
import ControlPanel from './ControlPanel';
import OverviewPanel from './OverviewPanel';
import TrendCharts from './TrendCharts';
import RechartsLineChart from './RechartsLineChart';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Button, Box, Paper, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import { fetchMetrics, analyzeMetricsData, downloadReport, fetchMetricsMetrika, fetchMetricsAnalytics } from '../services/apiService';
import { handleApiError } from '../services/errorHandler';
import { setFilter, addAppliedFilter, removeAppliedFilter, clearFilters } from '../store/filterSlice';

Modal.setAppElement('#root');

function Metrics() {
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.filter.value);
  const appliedFilters = useSelector((state) => state.filter.appliedFilters);
  const [analysis, setAnalysis] = useState('');
  const [error, setError] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [lastUpdated, setLastUpdated] = useState('');
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [startDate, setStartDate] = useState('2023-01-01');
  const [endDate, setEndDate] = useState('2023-12-31');
  const [chartType, setChartType] = useState('line');

  // Используем функции из apiService для получения данных
  const { data: metricsMetrika, error: errorMetrika, isLoading: isLoadingMetrika, refetch: refetchMetrika } = useQuery(
    ['metricsMetrika', startDate, endDate],
    () => fetchMetricsMetrika(startDate, endDate),
    {
      onError: (error) => handleApiError(error),
    }
  );

  const { data: metricsAnalytics, error: errorAnalytics, isLoading: isLoadingAnalytics, refetch: refetchAnalytics } = useQuery(
    ['metricsAnalytics', startDate, endDate],
    () => fetchMetricsAnalytics(startDate, endDate),
    {
      onError: (error) => handleApiError(error),
    }
  );

  // Используем fetchMetrics для загрузки метрик
  useEffect(() => {
    const loadMetrics = async () => {
      try {
        const metrics = await fetchMetrics(startDate, endDate);
        console.log('Loaded metrics:', metrics);
        // Дополнительная логика обработки полученных метрик
      } catch (err) {
        const formattedError = handleApiError(err);
        setError(formattedError);
        toast.error(`Ошибка загрузки метрик: ${formattedError}`);
      }
    };

    loadMetrics();
  }, [startDate, endDate]);

  // Обновление времени последнего обновления
  useEffect(() => {
    if (metricsMetrika && metricsAnalytics) {
      const lastUpdatedMetrika = metricsMetrika?.[metricsMetrika.length - 1]?.last_updated;
      const lastUpdatedAnalytics = metricsAnalytics?.[metricsAnalytics.length - 1]?.last_updated;
      setLastUpdated(lastUpdatedMetrika || lastUpdatedAnalytics);
    }
  }, [metricsMetrika, metricsAnalytics]);

  // Анализ метрик через apiService
  const analyzeMetrics = async () => {
    toast.info('Начинается анализ...', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    try {
      const data = await analyzeMetricsData(metricsMetrika, metricsAnalytics);
      setAnalysis(data.analysis);
      toast.success('Анализ успешно завершен!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      const formattedError = handleApiError(err);
      setError(formattedError);
      toast.error(`Ошибка анализа: ${formattedError}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Обработчик ошибок
  useEffect(() => {
    if (errorMetrika) {
      toast.error(`Ошибка при получении метрик Яндекс Метрики: ${handleApiError(errorMetrika)}`);
    }
    if (errorAnalytics) {
      toast.error(`Ошибка при получении метрик Google Analytics: ${handleApiError(errorAnalytics)}`);
    }
  }, [errorMetrika, errorAnalytics]);

  // Загрузка отчета через apiService
  const downloadReportHandler = async () => {
    try {
      await downloadReport(analysis);
      toast.success('Отчет успешно загружен', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      const formattedError = handleApiError(err);
      setError(formattedError);
      toast.error(`Ошибка загрузки отчета: ${formattedError}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Экспорт в Excel
  const exportToExcel = () => {
    const wsMetrika = utils.json_to_sheet(filteredMetricsMetrika);
    const wsAnalytics = utils.json_to_sheet(filteredMetricsAnalytics);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, wsMetrika, 'Yandex Metrika');
    utils.book_append_sheet(wb, wsAnalytics, 'Google Analytics');
    writeFile(wb, 'metrics.xlsx');
    toast.success('Данные экспортированы в Excel', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // Экспорт в CSV
  const exportToCSV = () => {
    const parser = new Parser();
    const csvMetrika = parser.parse(filteredMetricsMetrika);
    const csvAnalytics = parser.parse(filteredMetricsAnalytics);

    const blobMetrika = new Blob([csvMetrika], { type: 'text/csv;charset=utf-8;' });
    const blobAnalytics = new Blob([csvAnalytics], { type: 'text/csv;charset=utf-8;' });

    saveAs(blobMetrika, 'metrics_metrika.csv');
    saveAs(blobAnalytics, 'metrics_analytics.csv');
    toast.success('Данные экспортированы в CSV', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // Фильтрация метрик Yandex Metrika
  const filteredMetricsMetrika = useMemo(() => {
    if (!metricsMetrika) return [];
    return metricsMetrika.filter(metric => metric.dimensions.some(dim => dim.name.toLowerCase().includes(filter.toLowerCase())))
                         .sort((a, b) => {
                           const dateA = new Date(a.dimensions[0].name);
                           const dateB = new Date(b.dimensions[0].name);
                           return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
                         });
  }, [metricsMetrika, filter, sortOrder]);

  // Фильтрация метрик Google Analytics
  const filteredMetricsAnalytics = useMemo(() => {
    if (!metricsAnalytics) return [];
    return metricsAnalytics.filter(metric => metric['ga:sourceMedium'].toLowerCase().includes(filter.toLowerCase()) || 
                                              metric['ga:deviceCategory'].toLowerCase().includes(filter.toLowerCase()))
                           .sort((a, b) => {
                             const dateA = new Date(a['ga:date']);
                             const dateB = new Date(b['ga:date']);
                             return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
                           });
  }, [metricsAnalytics, filter, sortOrder]);

  // Применение и удаление фильтров
  const handleApplyFilter = (filter) => {
    dispatch(addAppliedFilter(filter));
  };

  const handleRemoveFilter = (filter) => {
    dispatch(removeAppliedFilter(filter));
  };

  // Данные для мульти-графиков
  const multiChartData = useMemo(() => ({
    labels: filteredMetricsMetrika.map(metric => metric.dimensions[0].name),
    datasets: [
      {
        label: 'Просмотры страниц (Yandex Metrika)',
        data: filteredMetricsMetrika.map(metric => metric.metrics[0]),
        borderColor: 'rgba(75,192,192,1)',
        fill: false,
      },
      {
        label: 'Визиты (Yandex Metrika)',
        data: filteredMetricsMetrika.map(metric => metric.metrics[1]),
        borderColor: 'rgba(255,99,132,1)',
        fill: false,
      },
      {
        label: 'Просмотры страниц (Google Analytics)',
        data: filteredMetricsAnalytics.map(metric => metric['ga:pageviews']),
        borderColor: 'rgba(153,102,255,1)',
        fill: false,
      },
      {
        label: 'Сеансы (Google Analytics)',
        data: filteredMetricsAnalytics.map(metric => metric['ga:sessions']),
        borderColor: 'rgba(54,162,235,1)',
        fill: false,
      },
    ],
  }), [filteredMetricsMetrika, filteredMetricsAnalytics]);

  // Данные для детализированных графиков
  const detailedChartData = useMemo(() => ({
    labels: filteredMetricsMetrika.map(metric => metric.dimensions[0].name),
    datasets: [
      {
        label: 'Показатель отказов (Yandex Metrika)',
        data: filteredMetricsMetrika.map(metric => metric.metrics[3]),
        borderColor: 'rgba(255,159,64,1)',
        fill: false,
      },
      {
        label: 'Средняя длительность визита (Yandex Metrika)',
        data: filteredMetricsMetrika.map(metric => metric.metrics[4]),
        borderColor: 'rgba(75,192,192,1)',
        fill: false,
      },
      {
        label: 'Показатель отказов (Google Analytics)',
        data: filteredMetricsAnalytics.map(metric => metric['ga:bounceRate']),
        borderColor: 'rgba(153,102,255,1)',
        fill: false,
      },
      {
        label: 'Средняя длительность сеанса (Google Analytics)',
        data: filteredMetricsAnalytics.map(metric => metric['ga:avgSessionDuration']),
        borderColor: 'rgba(54,162,235,1)',
        fill: false,
      },
      {
        label: 'Достижения целей (Yandex Metrika)',
        data: filteredMetricsMetrika.map(metric => metric.metrics[7]),
        borderColor: 'rgba(255,206,86,1)',
        fill: false,
      },
      {
        label: 'Завершения целей (Google Analytics)',
        data: filteredMetricsAnalytics.map(metric => metric['ga:goalCompletionsAll']),
        borderColor: 'rgba(75,192,192,1)',
        fill: false,
      },
    ],
  }), [filteredMetricsMetrika, filteredMetricsAnalytics]);

  // Данные для трендовых графиков
  const trendData = useMemo(() => {
    const labels = filteredMetricsMetrika.map(metric => metric.dimensions[0].name);

    const datasets = [
      {
        label: 'Тренд просмотров страниц (Yandex Metrika)',
        data: filteredMetricsMetrika.map(metric => metric.metrics[0]),
        borderColor: 'rgba(75,192,192,1)',
        fill: false,
      },
      {
        label: 'Тренд просмотров страниц (Google Analytics)',
        data: filteredMetricsAnalytics.map(metric => metric['ga:pageviews']),
        borderColor: 'rgba(153,102,255,1)',
        fill: false,
      },
    ];

    return { labels, datasets };
  }, [filteredMetricsMetrika, filteredMetricsAnalytics]);

  // Данные для графиков Recharts
  const rechartsData = useMemo(() => {
    return filteredMetricsMetrika.map(metric => ({
      name: metric.dimensions[0].name,
      value: metric.metrics[0],
    }));
  }, [filteredMetricsMetrika]);

  // Обработчик клика на метрику
  const handleMetricClick = (metric) => {
    setSelectedMetric(metric);
  };

  return (
    <Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Метрики
      </Typography>
      <ToastContainer />
      {isLoadingMetrika || isLoadingAnalytics ? <Typography>Загрузка...</Typography> : null}
      {error && <Typography color="error" className="error-message">{error}</Typography>}
      {lastUpdated && <Typography className="last-updated">Последнее обновление: {new Date(lastUpdated).toLocaleString()}</Typography>}
      <ControlPanel
        filter={filter}
        setFilter={(value) => dispatch(setFilter(value))}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        chartType={chartType}
        setChartType={setChartType}
        clearFilters={() => {
          dispatch(clearFilters());
          setSortOrder('asc');
        }}
        applyFilter={handleApplyFilter}
        removeFilter={handleRemoveFilter}
        appliedFilters={appliedFilters}
      />
      <Button
        onClick={() => {
          refetchMetrika();
          refetchAnalytics();
        }}
        disabled={isLoadingMetrika || isLoadingAnalytics}
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
      >
        Обновить данные
      </Button>
      <OverviewPanel metricsMetrika={metricsMetrika} metricsAnalytics={metricsAnalytics} />
      <MetricCharts multiChartData={multiChartData} detailedChartData={detailedChartData} chartType={chartType} />
      <TrendCharts trendData={trendData} />
      <RechartsLineChart data={rechartsData} title="Просмотры страниц (Recharts)" />
      <SummaryReport filteredMetricsMetrika={filteredMetricsMetrika} filteredMetricsAnalytics={filteredMetricsAnalytics} />
      <Box className="metrics-container">
        <Box className="metrics-section">
          <Typography variant="h6" component="h2">
            Yandex Metrika
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Дата</TableCell>
                  <TableCell>Источник</TableCell>
                  <TableCell>Устройство</TableCell>
                  <TableCell>Просмотры страниц</TableCell>
                  <TableCell>Визиты</TableCell>
                  <TableCell>Пользователи</TableCell>
                  <TableCell>Показатель отказов</TableCell>
                  <TableCell>Средняя длительность визита (сек)</TableCell>
                  <TableCell>Глубина просмотра</TableCell>
                  <TableCell>Новые пользователи</TableCell>
                  <TableCell>Достижения целей</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredMetricsMetrika.map((metric, index) => (
                  <TableRow key={index} onClick={() => handleMetricClick(metric)}>
                    <TableCell>{metric.dimensions[0].name}</TableCell>
                    <TableCell>{metric.dimensions[1].name}</TableCell>
                    <TableCell>{metric.dimensions[2].name}</TableCell>
                    <TableCell>{metric.metrics[0]}</TableCell>
                    <TableCell>{metric.metrics[1]}</TableCell>
                    <TableCell>{metric.metrics[2]}</TableCell>
                    <TableCell>{metric.metrics[3]}</TableCell>
                    <TableCell>{metric.metrics[4]}</TableCell>
                    <TableCell>{metric.metrics[5]}</TableCell>
                    <TableCell>{metric.metrics[6]}</TableCell>
                    <TableCell>{metric.metrics[7]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box className="metrics-section">
          <Typography variant="h6" component="h2">
            Google Analytics
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Дата</TableCell>
                  <TableCell>Источник</TableCell>
                  <TableCell>Устройство</TableCell>
                  <TableCell>Сеансы</TableCell>
                  <TableCell>Просмотры страниц</TableCell>
                  <TableCell>Пользователи</TableCell>
                  <TableCell>Показатель отказов</TableCell>
                  <TableCell>Средняя длительность сеанса (сек)</TableCell>
                  <TableCell>Завершения целей</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredMetricsAnalytics.map((metric, index) => (
                  <TableRow key={index} onClick={() => handleMetricClick(metric)}>
                    <TableCell>{metric['ga:date']}</TableCell>
                    <TableCell>{metric['ga:sourceMedium']}</TableCell>
                    <TableCell>{metric['ga:deviceCategory']}</TableCell>
                    <TableCell>{metric['ga:sessions']}</TableCell>
                    <TableCell>{metric['ga:pageviews']}</TableCell>
                    <TableCell>{metric['ga:users']}</TableCell>
                    <TableCell>{metric['ga:bounceRate']}</TableCell>
                    <TableCell>{metric['ga:avgSessionDuration']}</TableCell>
                    <TableCell>{metric['ga:goalCompletionsAll']}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Button onClick={analyzeMetrics} disabled={isLoadingMetrika || isLoadingAnalytics} variant="contained" color="primary" sx={{ mt: 2 }}>
        {isLoadingMetrika || isLoadingAnalytics ? 'Анализирую...' : 'Анализировать метрики'}
      </Button>
      <Button onClick={exportToExcel} disabled={isLoadingMetrika || isLoadingAnalytics} variant="contained" color="primary" sx={{ mt: 2 }}>
        Экспорт в Excel
      </Button>
      <Button onClick={exportToCSV} disabled={isLoadingMetrika || isLoadingAnalytics} variant="contained" color="primary" sx={{ mt: 2 }}>
        Экспорт в CSV
      </Button>
      {analysis && (
        <Paper elevation={3} sx={{ padding: 2, marginTop: 4 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Результат анализа
          </Typography>
          <pre>{analysis}</pre>
          <Button onClick={downloadReportHandler} variant="contained" color="primary" sx={{ mt: 2 }}>
            Скачать отчет
          </Button>
        </Paper>
      )}
      <Modal
        isOpen={!!selectedMetric}
        onRequestClose={() => setSelectedMetric(null)}
        contentLabel="Детали метрики"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
          }
        }}
      >
        {selectedMetric && (
          <div>
            <Typography variant="h5" component="h2" gutterBottom>
              Детали метрики
            </Typography>
            <pre>{JSON.stringify(selectedMetric, null, 2)}</pre>
            <Button onClick={() => setSelectedMetric(null)} variant="contained" color="primary" sx={{ mt: 2 }}>
              Закрыть
            </Button>
          </div>
        )}
      </Modal>
      <ReactTooltip />
    </Box>
  );
}

export default Metrics;
