// /var/www/myapp-client/src/store/userSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../services/apiService';
import { handleApiError } from '../services/errorHandler';
import { toast } from 'react-toastify';

// Асинхронное действие для загрузки профиля пользователя
export const fetchUserProfile = createAsyncThunk(
  'user/fetchUserProfile',
  async (_, { rejectWithValue }) => {
    try {
      const data = await apiService.get('/user/profile');
      return data;
    } catch (error) {
      handleApiError(error);
      return rejectWithValue(error.response?.data || 'Ошибка при загрузке профиля пользователя');
    }
  }
);

// Асинхронное действие для выхода пользователя
export const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (_, { dispatch }) => {
    try {
      // Очистка сессии
      apiService.clearSession();
      dispatch(logout());
    } catch (error) {
      handleApiError(error);
      toast.error('Ошибка при выходе из системы');
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    profile: null,
    loading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.profile = null;
    },
    setUser: (state, action) => {
      state.profile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error('Ошибка при загрузке профиля');
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.profile = null;
      });
  },
});

// Селекторы
export const selectIsAuthenticated = (state) => !!state.user.profile;
export const selectIsAdmin = (state) => state.user.profile?.role === 'admin';

// Экспорт действий и редьюсера
export const { logout, setUser } = userSlice.actions;
export default userSlice.reducer;

