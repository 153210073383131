// /var/www/myapp-client/src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'; // Импортируем Provider из Redux
import { PersistGate } from 'redux-persist/integration/react'; // Импортируем PersistGate для сохранения состояния
import './index.css'; // Импортируем стили
import App from './App'; // Импортируем корневой компонент приложения
import reportWebVitals from './reportWebVitals'; // Импортируем функцию для измерения производительности
import { store, persistor } from './store/store'; // Импортируем store и persistor

// Создаем корневой элемент для рендеринга приложения
const root = ReactDOM.createRoot(document.getElementById('root'));

// Рендеринг приложения в корневой элемент
root.render(
  <React.StrictMode>
    <Provider store={store}> {/* Оборачиваем приложение в Provider для подключения к Redux */}
      <PersistGate loading={null} persistor={persistor}> {/* Оборачиваем приложение в PersistGate для сохранения состояния */}
        <App /> {/* Основной компонент приложения */}
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// Отправляем данные о производительности приложения в консоль
reportWebVitals();

