// /var/www/myapp-client/src/components/OverviewPanel.js

import React, { useMemo, useCallback } from 'react';
import { Paper, Typography, Box } from '@mui/material';

const OverviewPanel = ({ metricsMetrika = [], metricsAnalytics = [] }) => {
  // Функция для безопасного доступа к метрикам
  const getMetricValue = useCallback((metric, fieldPath) => {
    return fieldPath.split('.').reduce((acc, part) => acc && acc[part], metric) || 0;
  }, []);

  // Функция для вычисления суммы по определенному полю
  const calculateTotal = useCallback((metrics, field) => {
    return metrics.reduce((total, metric) => total + getMetricValue(metric, field), 0);
  }, [getMetricValue]);

  // Оптимизация вычислений с использованием useMemo
  const totalPageviewsMetrika = useMemo(() => calculateTotal(metricsMetrika, 'metrics[0]'), [metricsMetrika, calculateTotal]);
  const totalVisitsMetrika = useMemo(() => calculateTotal(metricsMetrika, 'metrics[1]'), [metricsMetrika, calculateTotal]);
  const totalPageviewsAnalytics = useMemo(() => calculateTotal(metricsAnalytics, 'ga:pageviews'), [metricsAnalytics, calculateTotal]);
  const totalSessionsAnalytics = useMemo(() => calculateTotal(metricsAnalytics, 'ga:sessions'), [metricsAnalytics, calculateTotal]);

  return (
    <Paper elevation={3} sx={{ padding: 2, marginTop: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Обзор
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
        <Box sx={{ textAlign: 'center', flex: 1, minWidth: 200 }}>
          <Typography variant="h6" component="h3">
            Всего просмотров страниц (Metrika)
          </Typography>
          <Typography variant="body1">{totalPageviewsMetrika.toLocaleString()}</Typography>
        </Box>
        <Box sx={{ textAlign: 'center', flex: 1, minWidth: 200 }}>
          <Typography variant="h6" component="h3">
            Всего визитов (Metrika)
          </Typography>
          <Typography variant="body1">{totalVisitsMetrika.toLocaleString()}</Typography>
        </Box>
        <Box sx={{ textAlign: 'center', flex: 1, minWidth: 200 }}>
          <Typography variant="h6" component="h3">
            Всего просмотров страниц (Analytics)
          </Typography>
          <Typography variant="body1">{totalPageviewsAnalytics.toLocaleString()}</Typography>
        </Box>
        <Box sx={{ textAlign: 'center', flex: 1, minWidth: 200 }}>
          <Typography variant="h6" component="h3">
            Всего сеансов (Analytics)
          </Typography>
          <Typography variant="body1">{totalSessionsAnalytics.toLocaleString()}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default OverviewPanel;