// /var/www/myapp-client/src/components/MetricCharts.js

import React from 'react';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'; // Обновлено для chart.js 4.x
import zoomPlugin from 'chartjs-plugin-zoom';
import { Paper, Typography } from '@mui/material';
import { handleApiError } from '../services/errorHandler'; // Импорт улучшенного обработчика ошибок

// Регистрация всех необходимых компонентов и плагинов в Chart.js
Chart.register(...registerables, zoomPlugin);

const MetricCharts = ({ multiChartData, detailedChartData, chartType }) => {
  // Опции графиков, включая поддержку зума и панорамирования
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  // Маппинг типов графиков на компоненты Chart.js
  const chartComponents = {
    line: Line,
    bar: Bar,
    pie: Pie,
  };

  // Выбор компонента графика на основе типа
  const ChartComponent = chartComponents[chartType] || Line;

  return (
    <Paper elevation={3} sx={{ padding: 2, marginTop: 4 }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Сводные метрики
      </Typography>
      <div style={{ height: '400px', width: '100%' }}>
        <ChartComponent data={multiChartData} options={chartOptions} />
      </div>
      <Typography variant="h5" component="h1" gutterBottom sx={{ marginTop: 4 }}>
        Детализированные метрики
      </Typography>
      <div style={{ height: '400px', width: '100%' }}>
        <ChartComponent data={detailedChartData} options={chartOptions} />
      </div>
    </Paper>
  );
};

// Использование обработчика ошибок, если необходимо обрабатывать ошибки внутри компонента
try {
  // Ваш код здесь
} catch (error) {
  handleApiError(error); // Обработка ошибок с использованием улучшенного errorHandler
}

export default MetricCharts;

