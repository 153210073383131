// /var/www/myapp-client/src/api.js

import axios from 'axios';
import { toast } from 'react-toastify';

// Получаем базовый URL из переменных окружения
const baseURL = process.env.REACT_APP_API_BASE_URL || '/api';

// Создаем экземпляр axios с базовой конфигурацией
const apiClient = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json',
  },
  timeout: 15000, // Тайм-аут для всех запросов (15 секунд)
});

// Перехватчик для обработки ошибок и обновления токена
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      console.warn('Токен истек или недействителен. Попытка обновления токена.');

      try {
        const refreshResponse = await axios.post(`${baseURL}/auth/refresh-token`, {}, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
          }
        });

        const { token, refreshToken } = refreshResponse.data;

        // Обновляем токены в локальном хранилище
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);

        // Повторяем исходный запрос с новым токеном
        error.config.headers['Authorization'] = `Bearer ${token}`;
        return apiClient.request(error.config);
      } catch (refreshError) {
        console.error('Ошибка при обновлении токена:', refreshError);
        logout();
        throw refreshError;
      }
    }

    return Promise.reject(error);
  }
);

// Функция для обработки ошибок с логированием и уведомлением пользователя
const handleApiError = (error) => {
  let message = 'Произошла ошибка';

  if (error.response) {
    console.error('API Response Error:', error.response.data);
    message = error.response.data.message || 'Произошла ошибка на сервере';
  } else if (error.request) {
    console.error('API Request Error:', error.request);
    message = 'Сервер не отвечает. Проверьте подключение.';
  } else {
    console.error('API Setup Error:', error.message);
    message = 'Произошла ошибка при настройке запроса';
  }

  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });

  throw new Error(message);
};

// API функции

// Получение метрик администратора с логированием
export const fetchAdminMetrics = async () => {
  try {
    const response = await apiClient.get('/admin/metrics');
    console.info('Admin metrics fetched successfully', response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Получение метрик пользователя с логированием
export const fetchUserMetrics = async () => {
  try {
    const response = await apiClient.get('/user/metrics');
    console.info('User metrics fetched successfully', response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Получение списка всех пользователей (для админа)
export const fetchAllUsers = async () => {
  try {
    const response = await apiClient.get('/admin/users');
    console.info('Fetched all users successfully', response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Добавление нового пользователя (для админа)
export const createUser = async (userData) => {
  try {
    const response = await apiClient.post('/admin/users', userData);
    console.info('User created successfully', response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Обновление информации о пользователе (для админа)
export const updateUser = async (userId, userData) => {
  try {
    const response = await apiClient.put(`/admin/users/${userId}`, userData);
    console.info('User updated successfully', response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Удаление пользователя (для админа)
export const deleteUser = async (userId) => {
  try {
    const response = await apiClient.delete(`/admin/users/${userId}`);
    console.info('User deleted successfully', response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Получение списка всех сайтов
export const fetchAllWebsites = async () => {
  try {
    const response = await apiClient.get('/websites');
    console.info('Fetched all websites successfully', response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Добавление нового сайта
export const addWebsite = async (websiteData) => {
  try {
    const response = await apiClient.post('/websites', websiteData);
    console.info('Website added successfully', response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Обновление информации о сайте
export const updateWebsite = async (websiteId, websiteData) => {
  try {
    const response = await apiClient.put(`/websites/${websiteId}`, websiteData);
    console.info('Website updated successfully', response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Удаление сайта
export const deleteWebsite = async (websiteId) => {
  try {
    const response = await apiClient.delete(`/websites/${websiteId}`);
    console.info('Website deleted successfully', response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Анализ сайта
export const analyzeWebsite = async (websiteId) => {
  try {
    const response = await apiClient.post(`/websites/${websiteId}/analyze`);
    console.info('Website analysis completed successfully', response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Обновление настроек админа
export const updateAdminSettings = async (settingsData) => {
  try {
    const response = await apiClient.put('/admin/settings', settingsData);
    console.info('Admin settings updated successfully', response.data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Получение уведомлений через WebSocket с обработкой ошибок и переподключением
export const connectToNotifications = (callback) => {
  const socketUrl = process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:5001';
  const socket = new WebSocket(socketUrl);

  socket.onopen = () => {
    console.info('Connected to WebSocket for notifications');
  };

  socket.onmessage = (event) => {
    const message = JSON.parse(event.data);
    console.info('Received WebSocket message:', message);
    callback(message);
  };

  socket.onerror = (error) => {
    console.error('WebSocket error:', error);
    toast.error('Ошибка при получении уведомлений. Проверьте подключение.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  socket.onclose = () => {
    console.warn('WebSocket connection closed. Attempting to reconnect...');
    setTimeout(() => connectToNotifications(callback), 5000); // Переподключение через 5 секунд
  };

  return socket;
};

// Функция для выхода из системы и удаления токенов
export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  window.location.href = '/login';
};
