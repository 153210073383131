// /var/www/myapp-client/src/components/AdminDashboard.js

import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import RechartsLineChart from './RechartsLineChart';
import Metrics from './Metrics';
import Notification from './Notification';
import AdminSettings from './AdminSettings';
import AdminUserManagement from './AdminUserManagement';
import ManageWebsites from './ManageWebsites';
import withDataFetching from './withDataFetching';
import { fetchAdminMetrics } from '../services/apiService'; // Используем apiService для получения метрик

const AdminDashboard = ({ data: metricsData, error }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography variant="h4" gutterBottom>
            Панель администратора
          </Typography>
          {error && <Typography color="error">{error}</Typography>}
        </Paper>
      </Grid>

            <Grid item xs={12} md={6}>
        <RechartsLineChart
          data={metricsData}
          title="Тренды посещаемости"
          xAxisKey="date"
          lines={[
            { key: 'visits', color: '#ff7300' },
            { key: 'pageviews', color: '#387908' },
          ]}
          grid
          tooltip
          legend
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Metrics />
      </Grid>

      <Grid item xs={12} md={6}>
        <AdminUserManagement />
      </Grid>

      <Grid item xs={12} md={6}>
        <ManageWebsites />
      </Grid>

      <Grid item xs={12}>
        <Notification />
      </Grid>

      <Grid item xs={12}>
        <AdminSettings />
      </Grid>
    </Grid>
  );
};

export default withDataFetching(AdminDashboard, fetchAdminMetrics);
