// /var/www/myapp-client/src/store/filterSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filter: '',
  sortOrder: 'asc',  // Поле для сортировки по возрастанию/убыванию
  startDate: null,    // Начальная дата для фильтрации
  endDate: null,      // Конечная дата для фильтрации
  chartType: 'line',  // Тип графика (линейный, столбчатый и т.д.)
  appliedFilters: {}, // Объект для хранения примененных фильтров
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setSortOrder: (state, action) => {
      state.sortOrder = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setChartType: (state, action) => {
      state.chartType = action.payload;
    },
    addAppliedFilter: (state, action) => {
      const { filterName, filterValue } = action.payload;
      state.appliedFilters[filterName] = filterValue;
    },
    removeAppliedFilter: (state, action) => {
      const filterName = action.payload;
      delete state.appliedFilters[filterName];
    },
    clearFilters: () => {
      // Сброс всех фильтров к начальному состоянию
      return initialState;
    },
  },
});

export const { 
  setFilter, 
  setSortOrder, 
  setStartDate, 
  setEndDate, 
  setChartType, 
  addAppliedFilter, 
  removeAppliedFilter, 
  clearFilters 
} = filterSlice.actions;

export default filterSlice.reducer;

