// /var/www/myapp-client/src/components/Home.js

import React from 'react';
import { Typography, Paper, Button, Grid, Box } from '@mui/material';
import { toast } from 'react-toastify';
import './Home.css';
import { handleApiError } from '../services/errorHandler'; // Импортируем handleApiError для обработки ошибок

const Home = () => {
  const handleButtonClick = (message) => {
    toast.info(message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleYandexLogin = () => {
    try {
      const clientId = process.env.REACT_APP_YANDEX_CLIENT_ID;
      const redirectUri = process.env.REACT_APP_YANDEX_REDIRECT_URI;

      if (!clientId || !redirectUri) {
        throw new Error('YANDEX_CLIENT_ID или YANDEX_REDIRECT_URI не указаны в переменных окружения.');
      }

      const yandexAuthUrl = `https://oauth.yandex.ru/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
      window.location.href = yandexAuthUrl;
    } catch (error) {
      console.error('Ошибка при попытке авторизации через Яндекс:', error);
      handleApiError(error); // Используем handleApiError для обработки ошибок
    }
  };

  const features = [
    {
      title: 'Мощный анализ данных',
      text: 'Используйте GPT-4 для анализа данных из Яндекс Метрики и Google Analytics.',
    },
    {
      title: 'Улучшайте производительность',
      text: 'Получайте рекомендации по улучшению вашего сайта на основе данных и анализа.',
    },
    {
      title: 'Легкая интеграция',
      text: 'Подключите свои учетные записи Яндекс Метрики и Google Analytics в несколько кликов.',
    },
    {
      title: 'Доступные отчеты',
      text: 'Получайте подробные отчеты о ключевых показателях вашего сайта.',
    },
  ];

  return (
    <Box sx={{ backgroundColor: '#f0f4f8', minHeight: '100vh', paddingTop: 4 }}>
      <Paper elevation={3} sx={{ padding: 5, maxWidth: 800, margin: 'auto' }}>
        <Typography
          variant="h3"
          gutterBottom
          align="center"
          sx={{ fontWeight: 'bold', color: '#1976d2' }}
        >
          Добро пожаловать в AIWebMetrics
        </Typography>
        <Typography variant="h6" paragraph align="center" sx={{ color: '#555' }}>
          AIWebMetrics — это передовое веб-приложение, которое использует мощь GPT-4 для анализа данных из Яндекс Метрики и Google Analytics. Получите глубокое понимание производительности вашего сайта и улучшайте его на основе точных данных.
        </Typography>
        <Grid container spacing={4} sx={{ marginTop: 4 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Box sx={{ textAlign: 'center', padding: 2 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>
                  {feature.title}
                </Typography>
                <Typography variant="body1" sx={{ color: '#666', marginBottom: 2 }}>
                  {feature.text}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2} justifyContent="center" sx={{ marginTop: 4 }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleButtonClick('Перенаправление на авторизацию через Яндекс');
                handleYandexLogin();
              }}
              sx={{ textTransform: 'none' }}
            >
              Войти через Яндекс
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Home;