// /var/www/myapp-client/src/services/adminService.js

import apiService from './apiService';
import { handleApiError } from './errorHandler'; // Используем улучшенный errorHandler

const adminService = {
    // Получение всех пользователей
    fetchUsers: async () => {
        try {
            const response = await apiService.get('/admin/users');
            return response.data;  // Возвращаем только данные
        } catch (error) {
            handleApiError(error);
            throw new Error('Ошибка при получении списка пользователей');
        }
    },

    // Обновление роли пользователя
    changeUserRole: async (userId, role) => {
        try {
            const response = await apiService.put(`/admin/users/${userId}/role`, { role });
            return response.data;  // Возвращаем только данные
        } catch (error) {
            handleApiError(error);
            throw new Error('Ошибка при обновлении роли пользователя');
        }
    },

    // Удаление пользователя
    removeUserById: async (userId) => {
        try {
            const response = await apiService.delete(`/admin/users/${userId}`);
            return response.data;  // Возвращаем только данные
        } catch (error) {
            handleApiError(error);
            throw new Error('Ошибка при удалении пользователя');
        }
    },

    // Получение настроек администратора
    fetchAdminSettings: async () => {
        try {
            const response = await apiService.get('/admin/settings');
            return response.data;  // Возвращаем только данные
        } catch (error) {
            handleApiError(error);
            throw new Error('Ошибка при получении настроек администратора');
        }
    },

    // Обновление настроек администратора
    updateAdminSettings: async (settings) => {
        try {
            const response = await apiService.put('/admin/settings', settings);
            return response.data;  // Возвращаем только данные
        } catch (error) {
            handleApiError(error);
            throw new Error('Ошибка при обновлении настроек администратора');
        }
    }
};

export default adminService;

