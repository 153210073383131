// /var/www/myapp-client/src/App.js

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { CssBaseline, AppBar, Toolbar, Typography, Container, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Profile from './components/Profile';
import ManageWebsites from './components/ManageWebsites';
import AdminUserManagement from './components/AdminUserManagement';
import OpenAIQuery from './components/OpenAIQuery';
import Notification from './components/Notification';
import AdminSettings from './components/AdminSettings';
import Home from './components/Home';
import Metrics from './components/Metrics';
import AdminDashboard from './components/AdminDashboard';
import UserDashboard from './components/UserDashboard';
import authService from './services/authService';
import { selectIsAuthenticated, selectIsAdmin, setUser, logoutUser } from './store/userSlice';
import { persistor } from './store/store';

function App() {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const isAdmin = useSelector(selectIsAdmin);

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const token = await authService.refreshToken();
                if (token) {
                    const user = authService.getUser();
                    dispatch(setUser(user));
                } else {
                    dispatch(logoutUser());
                }
            } catch (error) {
                console.error('Ошибка при проверке аутентификации:', error);
                dispatch(logoutUser());
            }
        };

        if (!isAuthenticated) {
            checkAuthentication();
        }
    }, [dispatch, isAuthenticated]);

    const handleLogout = () => {
        authService.logout();
        dispatch(logoutUser());
        window.location.href = '/';
    };

    return (
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <CssBaseline />
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" sx={{ flexGrow: 1 }}>
                            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                                AIWebMetrics
                            </Link>
                        </Typography>
                        {isAuthenticated ? (
                            <>
                                <Button color="inherit" component={Link} to="/profile">Профиль</Button>
                                <Button color="inherit" onClick={handleLogout}>Выход</Button>
                            </>
                        ) : null}
                    </Toolbar>
                </AppBar>
                <Container>
                    <Routes>
                        <Route path="/profile" element={isAuthenticated ? <Profile /> : <Navigate to="/" />} />
                        <Route path="/manage-websites" element={isAuthenticated ? <ManageWebsites /> : <Navigate to="/" />} />
                        <Route path="/admin-user-management" element={isAdmin ? <AdminUserManagement /> : <Navigate to="/" />} />
                        <Route path="/openai-query" element={isAuthenticated ? <OpenAIQuery /> : <Navigate to="/" />} />
                        <Route path="/notifications" element={isAuthenticated ? <Notification /> : <Navigate to="/" />} />
                        <Route path="/admin-settings" element={isAdmin ? <AdminSettings /> : <Navigate to="/" />} />
                        <Route path="/dashboard" element={isAuthenticated ? (isAdmin ? <AdminDashboard /> : <UserDashboard />) : <Navigate to="/" />} />
                        <Route path="/metrics" element={isAuthenticated ? <Metrics /> : <Navigate to="/" />} />
                        <Route path="/" element={<Home />} />
                    </Routes>
                </Container>
            </Router>
        </PersistGate>
    );
}

export default App;
