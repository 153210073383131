// /var/www/myapp-client/src/components/ControlPanel.js

import React from 'react';
import { TextField, Select, MenuItem, Button, Paper, Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSortOrder, setStartDate, setEndDate, setChartType, setFilter, clearFilters } from '../store/filterSlice';
import { toast } from 'react-toastify';
import { handleApiError } from '../services/errorHandler'; // Импортируем handleApiError для обработки ошибок

const ControlPanel = () => {
  const dispatch = useDispatch();
  const { filter, sortOrder, startDate, endDate, chartType } = useSelector((state) => state.filter);

  // Объединенный обработчик изменений с обработкой ошибок
  const handleChange = (type) => (e) => {
    try {
      const value = e.target.value;
      switch (type) {
        case 'filter':
          dispatch(setFilter(value));
          break;
        case 'sortOrder':
          dispatch(setSortOrder(value));
          break;
        case 'startDate':
          dispatch(setStartDate(value));
          break;
        case 'endDate':
          dispatch(setEndDate(value));
          break;
        case 'chartType':
          dispatch(setChartType(value));
          break;
        default:
          break;
      }
    } catch (error) {
      handleApiError(error); // Обработка ошибок с использованием улучшенного errorHandler
    }
  };

  const handleClearFilters = () => {
    try {
      dispatch(clearFilters());
      toast.info('Фильтры сброшены');
    } catch (error) {
      handleApiError(error); // Обработка ошибок при сбросе фильтров
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, marginTop: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Панель управления
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Box sx={{ flex: 1, margin: 1 }}>
          <TextField
            label="Фильтр"
            type="text"
            value={filter}
            onChange={handleChange('filter')}
            fullWidth
          />
          <Select
            value={sortOrder}
            onChange={handleChange('sortOrder')}
            fullWidth
            sx={{ marginTop: 2 }}
          >
            <MenuItem value="asc">По возрастанию</MenuItem>
            <MenuItem value="desc">По убыванию</MenuItem>
          </Select>
        </Box>
        <Box sx={{ flex: 1, margin: 1 }}>
          <TextField
            label="Дата начала"
            type="date"
            value={startDate}
            onChange={handleChange('startDate')}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Дата окончания"
            type="date"
            value={endDate}
            onChange={handleChange('endDate')}
            fullWidth
            sx={{ marginTop: 2 }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box sx={{ flex: 1, margin: 1 }}>
          <Select
            value={chartType}
            onChange={handleChange('chartType')}
            fullWidth
          >
            <MenuItem value="line">Линейный</MenuItem>
            <MenuItem value="bar">Столбчатый</MenuItem>
            <MenuItem value="pie">Круговой</MenuItem>
          </Select>
        </Box>
      </Box>
      <Button onClick={handleClearFilters} variant="contained" color="secondary">
        Сбросить фильтры
      </Button>
    </Paper>
  );
};

export default ControlPanel;

