// /var/www/myapp-client/src/store/adminSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../services/apiService';
import { handleApiError } from '../services/errorHandler';
import { toast } from 'react-toastify';

// Асинхронные действия для получения данных
export const fetchAdminSettings = createAsyncThunk(
  'admin/fetchAdminSettings',
  async (_, { rejectWithValue }) => {
    try {
      const data = await apiService.get('/admin/settings');
      return data;
    } catch (error) {
      handleApiError(error);
      return rejectWithValue(error.response?.data || 'Ошибка при загрузке настроек администратора');
    }
  }
);

export const fetchAllUsers = createAsyncThunk(
  'admin/fetchAllUsers',
  async (_, { rejectWithValue }) => {
    try {
      const data = await apiService.get('/admin/users');
      return data;
    } catch (error) {
      handleApiError(error);
      return rejectWithValue(error.response?.data || 'Ошибка при загрузке пользователей');
    }
  }
);

export const fetchWebsites = createAsyncThunk(
  'admin/fetchWebsites',
  async (_, { rejectWithValue }) => {
    try {
      const data = await apiService.get('/websites');
      return data;
    } catch (error) {
      handleApiError(error);
      return rejectWithValue(error.response?.data || 'Ошибка при загрузке сайтов');
    }
  }
);

// Slice для управления состоянием администратора
const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    settings: null,
    users: [],
    websites: [],
    loading: false,
    error: null,
  },
  reducers: {
    updateUserRoleInStore: (state, action) => {
      const { id, role } = action.payload;
      const user = state.users.find(user => user._id === id);
      if (user) {
        user.role = role;
      }
    },
    removeUser: (state, action) => {
      state.users = state.users.filter(user => user._id !== action.payload);
    },
    addWebsite: (state, action) => {
      state.websites.push(action.payload);
    },
    removeWebsite: (state, action) => {
      state.websites = state.websites.filter(website => website._id !== action.payload);
    },
    updateWebsite: (state, action) => {
      const index = state.websites.findIndex(website => website._id === action.payload._id);
      if (index !== -1) {
        state.websites[index] = action.payload;
      }
    },
    updateAdminSettingsInStore: (state, action) => {
      state.settings = action.payload;
    },
  },
  extraReducers: (builder) => {
    const handlePending = (state) => {
      state.loading = true;
      state.error = null;
    };

    const handleRejected = (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error(action.payload);
    };

    builder
      .addCase(fetchAdminSettings.pending, handlePending)
      .addCase(fetchAdminSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.settings = action.payload;
      })
      .addCase(fetchAdminSettings.rejected, handleRejected)
      .addCase(fetchAllUsers.pending, handlePending)
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchAllUsers.rejected, handleRejected)
      .addCase(fetchWebsites.pending, handlePending)
      .addCase(fetchWebsites.fulfilled, (state, action) => {
        state.loading = false;
        state.websites = action.payload;
      })
      .addCase(fetchWebsites.rejected, handleRejected);
  },
});

// Экспортируем действия и редьюсер
export const {
  updateUserRoleInStore,
  removeUser,
  addWebsite,
  removeWebsite,
  updateWebsite,
  updateAdminSettingsInStore,
} = adminSlice.actions;

export default adminSlice.reducer;


