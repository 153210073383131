// /var/www/myapp-client/src/services/errorHandler.js

import { toast } from 'react-toastify';

// Функция логирования ошибок (можно расширить для логирования в сторонние сервисы)
const logError = (error, context = 'General') => {
    if (process.env.NODE_ENV !== 'production') {
        console.error(`[${context}] Error:`, error);
    }
    // Здесь можно реализовать отправку ошибки в сторонний сервис логирования, например, Sentry или LogRocket
};

// Обработка ошибок API
export const handleApiError = (error, customMessage = null) => {
    let errorMessage = '';

    if (error.response) {
        // Сервер вернул ответ с ошибкой
        const { status, data } = error.response;
        logError(data, `API Response ${status}`);

        errorMessage = customMessage || data.message || 'Произошла ошибка на сервере';

        switch (status) {
            case 401:
                handleUnauthorizedError();
                break;
            case 403:
                errorMessage = 'Доступ запрещен';
                break;
            case 404:
                errorMessage = 'Ресурс не найден';
                break;
            case 500:
                errorMessage = 'Внутренняя ошибка сервера';
                break;
            default:
                errorMessage = data.message || 'Произошла неизвестная ошибка';
                break;
        }
    } else if (error.request) {
        // Запрос был отправлен, но ответа не получено
        logError('No response received from server', 'API Request');
        errorMessage = customMessage || 'Нет ответа от сервера. Проверьте соединение';
    } else {
        // Ошибка при настройке запроса
        logError(error.message, 'API Setup');
        errorMessage = customMessage || 'Произошла ошибка при настройке запроса';
    }

    // Вывод уведомления
    showToast(errorMessage, 'error');
};

// Обработка ошибки 401 (Unauthorized)
const handleUnauthorizedError = () => {
    showToast('Сессия истекла. Пожалуйста, войдите снова.', 'warning');
    // Логика для перенаправления на страницу входа
    // window.location.href = '/login';
};

// Функция для вывода уведомлений
const showToast = (message, type = 'error', duration = 5000) => {
    const toastOptions = {
        position: 'top-right',
        autoClose: duration,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };

    switch (type) {
        case 'success':
            toast.success(message, toastOptions);
            break;
        case 'info':
            toast.info(message, toastOptions);
            break;
        case 'warning':
            toast.warn(message, toastOptions);
            break;
        case 'error':
        default:
            toast.error(message, toastOptions);
            break;
    }
};

// Обработка ошибок валидации (например, при форме)
export const handleValidationError = (errors) => {
    errors.forEach((error) => {
        showToast(error.msg, 'warning', 3000);
    });
};

// Обработка ошибок сети (например, при отсутствии интернета)
export const handleNetworkError = () => {
    showToast('Проблемы с сетью. Проверьте ваше соединение.', 'error', 8000);
};

// Кастомное логирование ошибок
export const customLogError = (error, context = 'Custom Error') => {
    logError(error, context);
};

export default handleApiError;

