// /var/www/myapp-client/src/services/userService.js

import apiService from './apiService';
import { handleApiError } from './errorHandler'; // Используем улучшенный errorHandler

// Функция для получения профиля пользователя
const getUserProfile = async () => {
    try {
        const response = await apiService.get('/user/profile');
        return response.data;  // Возвращаем только данные
    } catch (error) {
        handleApiError(error);  // Используем улучшенный errorHandler
        throw new Error('Ошибка при получении профиля пользователя');
    }
};

// Функция для обновления профиля пользователя
const updateUserProfile = async (profileData) => {
    try {
        const response = await apiService.put('/user/profile', profileData);
        return response.data;  // Возвращаем только данные
    } catch (error) {
        handleApiError(error);  // Используем улучшенный errorHandler
        throw new Error('Ошибка при обновлении профиля пользователя');
    }
};

// Присваиваем объект переменной
const userService = {
    getUserProfile,
    updateUserProfile,
};

// Экспортируем переменную по умолчанию
export default userService;
