// /var/www/myapp-client/src/services/authService.js

import apiService from './apiService';
import { store } from '../store/store';

const authService = {
    loginWithYandex: () => {
        // Перенаправление на сервер для обработки авторизации через Яндекс
        window.location.href = `${process.env.REACT_APP_API_BASE_URL}/auth/yandex`;
    },

    handleYandexCallback: async (code) => {
        try {
            // Отправляем код, полученный от Яндекса, на сервер для получения токенов
            const response = await apiService.post('/auth/yandex/callback', { code });
            if (response.token) {
                authService.setSession(response.token, response.refreshToken, response.user);
                store.dispatch({ type: 'SET_USER', payload: response.user });
            } else {
                throw new Error('Неверный формат ответа от сервера');
            }
            return response;
        } catch (error) {
            console.error('Ошибка при обработке callback от Яндекса:', error);
            throw new Error(error.response?.data?.message || 'Ошибка авторизации через Яндекс.');
        }
    },

    logout: () => {
        authService.clearSession();
        store.dispatch({ type: 'CLEAR_USER' });
        window.location.href = '/login';
    },

    refreshToken: async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            authService.logout();
            return null;
        }

        try {
            const response = await apiService.post('/auth/refresh-token', {}, {
                headers: { Authorization: `Bearer ${refreshToken}` },
                timeout: 5000, // Тайм-аут 5 секунд
            });

            if (response.token) {
                authService.setSession(response.token, response.refreshToken, response.user);
                return response.token;
            } else {
                authService.logout();
            }
        } catch (error) {
            console.error('Ошибка при обновлении токена:', error);
            authService.logout();
        }
    },

    setSession: (token, refreshToken, user) => {
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('user', JSON.stringify(user));
    },

    clearSession: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
    },

    getToken: () => localStorage.getItem('token'),

    getUser: () => JSON.parse(localStorage.getItem('user')),

    isAuthenticated: () => !!localStorage.getItem('token'),

    isAdmin: () => {
        const user = authService.getUser();
        return user?.role === 'admin';
    }
};

export default authService;
