// /var/www/myapp-client/src/components/RechartsLineChart.js

import React from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Brush,
  ReferenceLine,
  ReferenceArea,
  ReferenceDot,
} from 'recharts';
import { Paper, Typography } from '@mui/material';

const RechartsLineChart = ({
  data,
  title,
  xAxisKey = 'name',
  lines = [{ key: 'value', color: '#8884d8' }],
  grid = true,
  tooltip = true,
  legend = true,
  brush = false,
  referenceLines = [],
  referenceAreas = [],
  referenceDots = [],
  yAxisLabel = '',
  xAxisLabel = '',
  xAxisFormatter = (value) => value,
  yAxisFormatter = (value) => value,
  onClick,
  onMouseOver,
}) => {
  return (
    <Paper elevation={3} sx={{ padding: 2, marginTop: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          {lines.map((line) => (
            <Line
              key={line.key}
              type="monotone"
              dataKey={line.key}
              stroke={line.color}
              onClick={onClick}
              onMouseOver={onMouseOver}
            />
          ))}
          {grid && <CartesianGrid stroke="#ccc" />}
          <XAxis 
            dataKey={xAxisKey} 
            label={{ value: xAxisLabel, position: 'insideBottomRight', offset: -5 }} 
            tickFormatter={xAxisFormatter}
          />
          <YAxis 
            label={{ value: yAxisLabel, angle: -90, position: 'insideLeft' }} 
            tickFormatter={yAxisFormatter}
          />
          {tooltip && <Tooltip />}
          {legend && <Legend />}
          {brush && <Brush />}
          {referenceLines.map((refLine, index) => (
            <ReferenceLine key={`refLine-${index}`} {...refLine} />
          ))}
          {referenceAreas.map((refArea, index) => (
            <ReferenceArea key={`refArea-${index}`} {...refArea} />
          ))}
          {referenceDots.map((refDot, index) => (
            <ReferenceDot key={`refDot-${index}`} {...refDot} />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
};

RechartsLineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  xAxisKey: PropTypes.string,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      color: PropTypes.string,
    })
  ),
  grid: PropTypes.bool,
  tooltip: PropTypes.bool,
  legend: PropTypes.bool,
  brush: PropTypes.bool,
  referenceLines: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      stroke: PropTypes.string,
      strokeWidth: PropTypes.number,
    })
  ),
  referenceAreas: PropTypes.arrayOf(
    PropTypes.shape({
      x1: PropTypes.number,
      x2: PropTypes.number,
      y1: PropTypes.number,
      y2: PropTypes.number,
      stroke: PropTypes.string,
      fill: PropTypes.string,
      fillOpacity: PropTypes.number,
    })
  ),
  referenceDots: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      stroke: PropTypes.string,
      fill: PropTypes.string,
      strokeWidth: PropTypes.number,
      r: PropTypes.number,
    })
  ),
  yAxisLabel: PropTypes.string,
  xAxisLabel: PropTypes.string,
  xAxisFormatter: PropTypes.func,
  yAxisFormatter: PropTypes.func,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
};

export default RechartsLineChart;
