// /var/www/myapp-client/src/store/notificationsSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid'; // Импортируем uuid для генерации уникальных ID

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    messages: [], // Массив для хранения уведомлений
  },
  reducers: {
    addNotification: (state, action) => {
      state.messages.push({
        id: uuidv4(), // Генерация уникального ID для каждого уведомления
        ...action.payload, // Сохранение данных уведомления
      });
    },
    removeNotification: (state, action) => {
      state.messages = state.messages.filter(msg => msg.id !== action.payload); // Удаление уведомления по ID
    },
    clearNotifications: (state) => {
      state.messages = []; // Очистка всех уведомлений
    },
  },
});

export const { addNotification, removeNotification, clearNotifications } = notificationsSlice.actions;
export default notificationsSlice.reducer;

