// /var/www/myapp-client/src/components/AdminUserManagement.js

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, IconButton, Typography, CircularProgress, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import adminService from '../services/adminService'; // Импорт из adminService
import { fetchAllUsers, removeUser, updateUserRoleInStore } from '../store/adminSlice';

const AdminUserManagement = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.admin.users || []);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        setLoading(true);
        await dispatch(fetchAllUsers()); // Используем fetchAllUsers для получения пользователей
      } catch (error) {
        setError('Ошибка при загрузке пользователей');
        toast.error(`Ошибка при загрузке пользователей: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (!users.length) {
      loadUsers();
    } else {
      setLoading(false);
    }
  }, [dispatch, users.length]);

  const handleRoleChange = async (id, role) => {
    try {
      await adminService.changeUserRole(id, role); // Используем adminService для обновления роли
      dispatch(updateUserRoleInStore({ id, role }));
      toast.success('Роль успешно обновлена');
    } catch (error) {
      toast.error(`Ошибка при обновлении роли: ${error.message}`);
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await adminService.removeUserById(id); // Используем adminService для удаления пользователя
      dispatch(removeUser(id));
      toast.success('Пользователь успешно удален');
    } catch (error) {
      toast.error(`Ошибка при удалении пользователя: ${error.message}`);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <Typography variant="body1" color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Paper elevation={3} sx={{ padding: 2, marginTop: 4 }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Управление пользователями
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Роль</TableCell>
              <TableCell>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <TableRow key={user._id}>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <Select
                    value={user.role}
                    onChange={(e) => handleRoleChange(user._id, e.target.value)}
                    fullWidth
                  >
                    <MenuItem value="user">Пользователь</MenuItem>
                    <MenuItem value="admin">Администратор</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteUser(user._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default AdminUserManagement;
