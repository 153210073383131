// /var/www/myapp-client/src/services/apiService.js

import axios from 'axios';
import authService from './authService'; // Импортируем весь authService
import { handleApiError } from './errorHandler'; // Импортируем улучшенный errorHandler

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || '/api',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 20000,
});

apiClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    console.info(`Отправка ${config.method.toUpperCase()} запроса на ${config.url}`);
    return config;
  },
  error => {
    console.error('Ошибка запроса:', error);
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  response => {
    console.info(`Получен ответ с кодом ${response.status} от ${response.config.url}`);
    return response;
  },
  async error => {
    console.error('Ошибка ответа:', error);

    if (error.response && error.response.status === 401) {
      try {
        const originalRequest = error.config;
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          throw new Error('Отсутствует refresh token');
        }

        const { data } = await axios.post('/api/auth/refresh-token', { token: refreshToken });

        localStorage.setItem('token', data.token);
        localStorage.setItem('refreshToken', data.refreshToken);

        apiClient.defaults.headers.common.Authorization = `Bearer ${data.token}`;
        originalRequest.headers.Authorization = `Bearer ${data.token}`;

        return apiClient(originalRequest);
      } catch (refreshError) {
        console.error('Ошибка при обновлении токена:', refreshError);
        authService.logout(); // Используем authService.logout()
        return Promise.reject(refreshError);
      }
    }

    // Используем улучшенный errorHandler для обработки ошибок
    handleApiError(error);
        return Promise.reject(error);
  }
);

// API functions for admin settings
export const fetchAdminSettings = async () => {
  try {
    const response = await apiClient.get('/admin/settings');
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const updateAdminSettings = async (settings) => {
  try {
    const response = await apiClient.put('/admin/settings', settings);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// API functions for admin metrics
export const fetchAdminMetrics = async () => {
  try {
    const response = await apiClient.get('/admin/metrics');
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// API functions for websites
export const fetchWebsites = async () => {
  try {
    const response = await apiClient.get('/websites');
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const addWebsite = async (website) => {
  try {
    const response = await apiClient.post('/websites', website);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const deleteWebsite = async (id) => {
  try {
    const response = await apiClient.delete(`/websites/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const analyzeWebsite = async (id) => {
  try {
    const response = await apiClient.get(`/websites/${id}/analyze`);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// API functions for user management
export const fetchAllUsers = async () => {
  try {
    const response = await apiClient.get('/users');
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const updateUserRole = async (id, role) => {
  try {
    const response = await apiClient.put(`/users/${id}/role`, { role });
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const deleteUserById = async (id) => {
  try {
    const response = await apiClient.delete(`/users/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// API functions for authentication and profile
export const registerUser = async (userData) => {
  try {
    const response = await apiClient.post('/auth/register', userData);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const loginUser = async (credentials) => {
  try {
    const response = await apiClient.post('/auth/login', credentials);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const verifyEmailToken = async (token) => {
  try {
    const response = await apiClient.post('/auth/verify-email', { token });
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getUserProfile = async () => {
  try {
    const response = await apiClient.get('/user/profile');
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const updateUserProfile = async (profileData) => {
  try {
    const response = await apiClient.put('/user/profile', profileData);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// Additional functions for notifications
export const fetchNotifications = async () => {
  try {
    const response = await apiClient.get('/notifications');
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const markNotificationAsRead = async (id) => {
  try {
    const response = await apiClient.put(`/notifications/${id}/read`);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// API functions for metrics (Google Analytics and Yandex Metrika)
export const fetchMetricsAnalytics = async (params) => {
  try {
    const response = await apiClient.get('/metrics/analytics', { params });
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const fetchMetricsMetrika = async (params) => {
  try {
    const response = await apiClient.get('/metrics/metrika', { params });
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const fetchMetricsByDate = async (startDate, endDate) => {
  try {
    const response = await apiClient.get('/metrics/date-range', {
      params: { startDate, endDate },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const fetchMetrics = async (params) => {
  try {
    const response = await apiClient.get('/metrics', { params });
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const analyzeMetricsData = async (data) => {
  try {
    const response = await apiClient.post('/metrics/analyze', data);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// API functions for OpenAI
export const sendOpenAIQuery = async (query) => {
  try {
    const response = await apiClient.post('/openai/query', { query });
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const fetchOpenAIHistory = async () => {
  try {
    const response = await apiClient.get('/openai/history');
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const deleteOpenAIQuery = async (queryId) => {
  try {
    const response = await apiClient.delete(`/openai/history/${queryId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// API functions for reports
export const downloadReport = async (reportId) => {
  try {
    const response = await apiClient.get(`/reports/${reportId}/download`, {
      responseType: 'blob',
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const generateReport = async (reportData) => {
  try {
    const response = await apiClient.post('/reports/generate', reportData);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const fetchReportStatus = async (reportId) => {
  try {
    const response = await apiClient.get(`/reports/${reportId}/status`);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// Экспорт API-клиента и вспомогательных функций
export const apiService = {
  get: async (url, params) => {
    try {
      const response = await apiClient.get(url, { params });
      return response.data;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  },
  post: async (url, data) => {
    try {
      const response = await apiClient.post(url, data);
      return response.data;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  },
  put: async (url, data) => {
    try {
      const response = await apiClient.put(url, data);
      return response.data;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  },
  delete: async (url) => {
    try {
      const response = await apiClient.delete(url);
      return response.data;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  },
};

export default apiClient;

