// /var/www/myapp-client/src/components/Notification.js

import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { List, ListItem, ListItemText, Paper, Typography, Box } from '@mui/material';
import { fetchNotifications } from '../services/apiService';
import { handleApiError } from '../services/errorHandler'; // Импорт улучшенного обработчика ошибок

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const loadNotifications = async () => {
      try {
        const fetchedNotifications = await fetchNotifications();
        setNotifications(fetchedNotifications);
        toast.success('Уведомления успешно загружены'); // Уведомление об успешной загрузке
      } catch (error) {
        handleApiError(error, 'Ошибка при загрузке уведомлений'); // Использование улучшенного errorHandler
        setError(`Ошибка при загрузке уведомлений: ${error.message}`);
        toast.error(`Ошибка при загрузке уведомлений: ${error.message}`); // Уведомление об ошибке
      } finally {
        setLoading(false);
      }
    };

    loadNotifications();
  }, []);

  return (
    <Box sx={{ padding: 2 }}>
      <ToastContainer />
      <Paper elevation={3} sx={{ padding: 2 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Уведомления
        </Typography>
        {loading && <Typography>Загрузка уведомлений...</Typography>}
        {error && <Typography color="error">{error}</Typography>}
        {notifications.length > 0 ? (
          <List>
            {notifications.map((notification) => (
              <ListItem key={notification.id || notification.message}>
                <ListItemText primary={notification.message} />
              </ListItem>
            ))}
          </List>
        ) : !loading && !error ? (
          <Typography>Нет доступных уведомлений</Typography>
        ) : null}
      </Paper>
    </Box>
  );
};

export default Notification;
