// /var/www/myapp-client/src/components/SummaryReport.js

import React from 'react';
import { Paper, Typography, Box } from '@mui/material';

const SummaryReport = ({ filteredMetricsMetrika = [], filteredMetricsAnalytics = [] }) => {
  
  // Функция для вычисления общей суммы по определенному полю
  const calculateTotal = (metrics, field) => {
    return metrics.reduce((total, metric) => total + parseFloat(metric[field] || 0), 0);
  };

  // Функция для вычисления среднего значения по определенному полю
  const calculateAverage = (metrics, field) => {
    if (metrics.length === 0) return 0;
    return (calculateTotal(metrics, field) / metrics.length).toFixed(2);
  };

  // Форматирование чисел для отображения
  const formatNumber = (num) => {
    return num.toLocaleString();
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, marginTop: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Summary Report
      </Typography>
      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="h6" component="h3" gutterBottom>
          Yandex Metrika
        </Typography>
        <Typography>Total Pageviews: {formatNumber(calculateTotal(filteredMetricsMetrika, 'metrics[0]'))}</Typography>
        <Typography>Total Visits: {formatNumber(calculateTotal(filteredMetricsMetrika, 'metrics[1]'))}</Typography>
        <Typography>Total Users: {formatNumber(calculateTotal(filteredMetricsMetrika, 'metrics[2]'))}</Typography>
        <Typography>Average Bounce Rate: {calculateAverage(filteredMetricsMetrika, 'metrics[3]')}%</Typography>
        <Typography>Average Visit Duration: {formatNumber(calculateAverage(filteredMetricsMetrika, 'metrics[4]'))} seconds</Typography>
        <Typography>Total Goal Reaches: {formatNumber(calculateTotal(filteredMetricsMetrika, 'metrics[7]'))}</Typography>
      </Box>
      <Box>
        <Typography variant="h6" component="h3" gutterBottom>
          Google Analytics
        </Typography>
        <Typography>Total Pageviews: {formatNumber(calculateTotal(filteredMetricsAnalytics, 'ga:pageviews'))}</Typography>
        <Typography>Total Sessions: {formatNumber(calculateTotal(filteredMetricsAnalytics, 'ga:sessions'))}</Typography>
        <Typography>Total Users: {formatNumber(calculateTotal(filteredMetricsAnalytics, 'ga:users'))}</Typography>
        <Typography>Average Bounce Rate: {calculateAverage(filteredMetricsAnalytics, 'ga:bounceRate')}%</Typography>
        <Typography>Average Session Duration: {formatNumber(calculateAverage(filteredMetricsAnalytics, 'ga:avgSessionDuration'))} seconds</Typography>
        <Typography>Total Goal Completions: {formatNumber(calculateTotal(filteredMetricsAnalytics, 'ga:goalCompletionsAll'))}</Typography>
      </Box>
    </Paper>
  );
};

export default SummaryReport;

